.face{
    max-width: 50px;;
    margin-right: 35px;
}
.link{
    text-decoration: none;
    font-weight: bold !important;
    color:orange !important;
    transform: capitalize;
}
.link:hover{
    box-shadow: 0 0 0 14px;
    transition: 0.3s;
    background-color: black;
}

@media only screen and (max-widTh: 700px){
    .face{margin-right: 0px;}
}
.root{
    flex-grow: 1;
    background-color:#0FBE7C;
    // z-index: 1 !important;
}