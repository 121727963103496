.home{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left: 0px;
    top:0px;
    color: blue;
    z-index: 100;
background-color: yellow;
animation: falls 1.7s forwards;

}


.home2{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:20vw;
    top:0px;
    color: blue;
    z-index: 100;
background-color: green;
animation: falls 1.7s forwards;

}
.home3{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:40vw;
    top:0px;
    color: blue;
    z-index: 100;
background-color: cyan;
animation: falls 1.7s forwards;

}
.home4{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:60vw;
    top:0px;
    color: blue;
    z-index: 100;
background-color: grey;
animation: falls 1.7s forwards;

}
.home5{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:80vw;
    top:0px;
    color: blue;
    z-index: 100;
background-color: red;
animation: falls 1.7s forwards;
}

@keyframes falls {
  0%   {width: 20vw;}
  100% {width: 0vw;
z-index: -1;}
}

.background{
}
.bubbles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.select{
    filter: brightness(70%);
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    align-self: center;
  transform: scale(0.6);
  z-index: 4;

}
.select:hover{
    transition: 0.3s all;
    filter: brightness(250%);
    // border: oldlace 4px solid;
    // padding: 10px 0px 1px 0px;
}


.remove{
display: none;
pointer-events: none;
}
.show{
pointer-events: all;
// z-index: -20;
}
.start{
    z-index: 3;
    position: relative;
    top:50px;
}

.titleImage{
    // overflow: hidden;
    position:absolute;
    left: 50%;       
    top:20px;
    z-index: -1;
    transform: translate(-50%, -50%) scale(0.9);
    max-width: 11700px;
        object-fit: cover;
    // border: 5px solid black;
    // animation:  flow 0.7s infinite alternate;
}
.vidProduction{
    z-index: -1;
    max-width: 800px;
    object-fit: cover;
    transform: scale(0.9);

}


.icon{
    filter: brightness(50%) sepia(0.5) saturate(30);
    padding:10px;
    // position:relative;
    right:0px;
    
}
.icon:hover{
    transition: 0.3s all;
    filter: brightness(200%) sepia(0.5) saturate(10);
    

}
.width{
    padding-right: 20px;
    width:100vw;
}
.flip{
    position:relative;
     top:50px;
}

@keyframes flow {
    from {filter: hue-rotate(30deg);}
    to{filter:hue-rotate(10deg);
    }
}

@media only screen and (max-width: 700px){
    .flip{
        position:relative;
         top:350px;
         left:50px;
    }
    

    .titleImage{
        transform: scale(0.9) translate(-50%, -50%);
        // padding:20px;
        // position:absolute;
        // left: 50%;       
        // top:20%;
        // z-index: -1;
        // transform: translate(-50%, -50%);
  }
  .select{
      position: relative;
      top:150px;
      padding:10px;
  }
    .icon{
        padding-top: 300px;
        position:relative;
        left:0;
        top:0;

    }
    .show{
        display: none;
        pointer-events: none;
    }
    .vidProduction{
        z-index: -1;
        max-width: 800px;
        position:relative;
        top:200px;
        left:50px;
        object-fit: cover;
    }
}

