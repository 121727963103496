.screen{
    width:100vw;
    height:100vh;
position: fixed;
top:0px
}


.background{
    background-color:black;
    animation: flippidyflip 3s forwards;
    height: 100vh;
}




@keyframes flippidyflip {
    from {background-color: black;}

    to {
        background-color: white;
    }
}




//animation

.home{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left: 0px;
    top:0px;
    color: blue;
    z-index: 100;
background-color: black;
animation: falls 1.7s forwards;

}


.home2{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:20vw;
    top:0px;
    color: blue;
    z-index: 100;
background-color: black;
animation: falls 1.7s forwards;

}
.home3{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:40vw;
    top:0px;
    color: blue;
    z-index: 100;
background-color: black;
animation: falls 1.7s forwards;

}
.home4{
    content:"";
    height:100vh;
    width: 20vw;
    position: absolute;
    left:60vw;
    top:0px;
    color: black;
    z-index: 100;
background-color: black;
animation: falls 1.7s forwards;

}
.home5{
    content:"";
    height:100vh;
    width: 20vw;
    position: fixed;
    left:80vw;
    top:-20px;
    color: blue;
    z-index: 100;
background-color: black;
animation: falls 1.7s forwards;
}


@keyframes falls {
    0%   {width: 20vw;}
    20% {width: 20vw}
    40% {width: 15vw}
    60% {width: 10vw}
    80%{width:5vw}
    100% {width: 0vw;
  z-index: -1;}
  }
  

  .button{
      color: orange !important;
      background-color: white !important;
      z-index: 12 ;
      margin: 0 auto !important;
      opacity: 0.2;
      text-transform:capitalize !important;
      font-weight: bold !important;
      font-size: large !important;
      position: relative;
      top:100px;
      
  }

  .button:hover{
opacity: 1;
transition: 0.6s all;
  }


  
.ome{
    content:"";
    height:50vh;
    width: 20vw;
    position: absolute;
    left: 0px;
    top:50vh;
    color: blue;
    z-index: 100;
background-color: black;
animation: lifts 1.7s forwards;
// transform: rotate(180deg);

}


.ome2{
    content:"";
    height:60vh;
    width: 20vw;
    position: absolute;
    left:20vw;
    top:60vh;
    color: blue;
    z-index: 100;
background-color: black;
animation: lifts 1.7s forwards;
// transform: rotate(180deg);

}
.ome3{
    content:"";
    height:50vh;
    width: 20vw;
    position: absolute;
    left:40vw;
    top:50vh;
    color: blue;
    z-index: 100;
background-color: black;
animation: lifts 1.7s forwards;
// transform: rotate(180deg);

}
.ome4{
    content:"";
    height:60vh;
    width: 20vw;
    position: absolute;
    left:60vw;
    top:60vh;
    color: black;
    z-index: 100;
background-color: black;
animation: lifts 1.7s forwards;
// transform: rotate(180deg);


}
.ome5{
    content:"";
    height:50vh;
    width: 20vw;
    position: fixed;
    left:80vw;
    top:50vh;
    color: blue;
    z-index: 100;
background-color: black;
// transform: rotate(90deg);
animation: lifts 1.7s forwards;


}

.rotate{
    // transform: rotate(180deg) !important;

}
@keyframes lifts {
    0%   {height: 100%;
        // transform: rotate(90deg)
        ;
    }
    100% {height: 0%;
        // transform: rotate(90deg)
        ;

  z-index: -1;}
  }
  